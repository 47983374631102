import { Pipe, PipeTransform } from '@angular/core';
import { PermissionsDTO } from '@api';
import { TokenService } from './token.service';

@Pipe({
  name: 'hasPermission',
})
export class PermissionPipe implements PipeTransform {
  constructor(private tokenService: TokenService) {}

  transform(permission: PermissionsDTO): boolean {
    return this.tokenService.hasPermission(permission);
  }
}
