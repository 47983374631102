import { Router } from '@angular/router';
import { SpaNavigationExtras } from './models';
import { TokenService } from './token.service';
import { AppInjector } from '../app-injector';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function navigate(commands: any[], extras?: SpaNavigationExtras) {
  let permitted = true;
  if (extras?.permission) {
    permitted = AppInjector.injector.get(TokenService).hasPermission(extras?.permission);
  }

  if (permitted) {
    AppInjector.injector.get(Router).navigate(commands, extras);
  }
}
