import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionsDTO } from '@api';
import { TokenService } from './token.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[hasPermission]',
})
export class HasPermissionDirective {
  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private tokenService: TokenService,
  ) {}

  /*
  If no permission is passed or the user has ANY of the passed permissions, then this qualifies as permitted
   */
  @Input()
  set hasPermission(permissionsDTO: PermissionsDTO | PermissionsDTO[]) {
    if (!permissionsDTO || !permissionsDTO.length || this.tokenService.hasPermission(permissionsDTO)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
