import { ModuleWithProviders, NgModule } from '@angular/core';
import { TokenService } from './token.service';
import { PermissionPipe } from './permission.pipe';
import { HasPermissionDirective } from './has-permission.directive';

const pipes = [PermissionPipe];
const directives = [HasPermissionDirective];

@NgModule({
  declarations: [...pipes, ...directives],
  exports: [...pipes, ...directives],
  imports: [],
})
export class PermissionModule {
  static forRoot(): ModuleWithProviders<PermissionModule> {
    return {
      ngModule: PermissionModule,

      providers: [TokenService],
    };
  }
}
