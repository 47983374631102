// @ts-strict-ignore
import { TokenService } from './token.service';
import { CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AppInjector } from '../app-injector';
import { SpaActivatedRouteSnapshot, SpaRedirectActivatedRouteSnapshot } from './models';

export const PermissionGuard: CanActivateFn = (route: SpaActivatedRouteSnapshot) => {
  const tokenService = AppInjector.injector.get(TokenService);
  const router = AppInjector.injector.get(Router);
  if (route.data?.permission) {
    const canActivate = tokenService.hasPermission(route.data?.permission);
    if (!canActivate) {
      router.navigateByUrl('home');
    }
    return canActivate;
  }
  return true;
};

export const RedirectGuard: CanActivateFn = (route: SpaRedirectActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const tokenService = AppInjector.injector.get(TokenService);
  const router = AppInjector.injector.get(Router);

  for (const childRoutes of route.parent.routeConfig.children) {
    const routePermissions = childRoutes.data?.['permission'] || [];
    if (tokenService.hasPermission(routePermissions)) {
      return router.parseUrl(`${state.url}/${childRoutes.path}`);
    }
  }

  return router.parseUrl('/home');
};
